import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
    loaderStatus: boolean = false;

    actionCode = "";

    actionMode = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private meta: Meta
    ) {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
    }

    ngOnInit() {
        this.loaderStatus = true;

        this.route.queryParams.subscribe(params => {
            this.actionMode = params['mode'];

            if (params['mode'] != "verifyEmail" && params['mode'] != "resetPassword") {
                this.router.navigate(['/login'])
            }
        });

        localStorage.removeItem('currentUser');
    }

    gotoSignIn() {
        this.router.navigate(['/login'])
    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        public fireAuth: AngularFireAuth
    ) { }

    postRequest(functionName: string, data: object): Observable<any> {
        var token = this.storageService.get('accessToken');

        const superUserData = this.storageService.getSuper('userData');

        if (superUserData) token = this.storageService.getSuper('accessToken')

        return this.http.post<any>(environment.firebaseFunctionUrl + functionName, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    postParentRequest(functionName: string, data: object): Observable<any> {
        var token = localStorage.getItem('accessToken');

        return this.http.post<any>(environment.firebaseFunctionUrl + functionName, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    checkToken(callback) {
        const superUserData = this.storageService.getSuper('userData');

        const expirationTime = parseInt(this.storageService.get('expirationTime'));
        const nowTime = (new Date()).getTime();

        if (nowTime > expirationTime) {
            this.fireAuth.auth.onAuthStateChanged(user => {
                this.fireAuth.auth.currentUser.getIdToken(true).then(async response => {
                    this.storageService.set('accessToken', response);

                    if (superUserData != null) {
                        localStorage.setItem('superAdmin_accessToken', response);

                        await this.setSuperAdminAT({ token: response }).toPromise();
                    }

                    this.storageService.set('expirationTime', nowTime + 3600000);
                    callback();
                }).catch(error => {
                    console.log('error', error);
                })
            });
        } else {
            callback();
        }
    }

    checkParentToken(callback) {
        const expirationTime = parseInt(localStorage.getItem('expirationTime') || "0");
        const nowTime = (new Date()).getTime();

        if (nowTime > expirationTime) {
            this.fireAuth.auth.onAuthStateChanged(user => {
                this.fireAuth.auth.currentUser.getIdToken(true).then(response => {
                    localStorage.setItem('accessToken', response);

                    localStorage.setItem('expirationTime', (nowTime + 3600000).toString());
                    callback();
                }).catch(error => {
                    console.log('error', error);
                })
            });
        } else {
            callback();
        }
    }

    setSuperAdminAT(data): Observable<any> {
        var token = localStorage.getItem('superAdmin_accessToken');

        return this.http.post<any>(environment.firebaseFunctionUrl + 'setSuperAdminAT', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }
}

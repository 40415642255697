import { generateUrl, generateSuperUrl } from '../helper';

export const PAGE = {
    default: generateUrl('dashboard'),
    superDefault: generateSuperUrl('dashboard'),
    login: '/login',
    error: generateUrl('error/not-found')
};

export const VIEW = {
    title: ''
};

export const ROLES = {
    superadmin: 'Super Admin',
    hyperadmin: 'Hyper Admin',
    admin: 'Admin',
    admin_teacher: 'Admin teacher',
    teacher: 'Teacher',
    student: 'Student'
};

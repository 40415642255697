import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { RegistrationComponent } from './registration/registration.component';
import { ForgotComponent } from './forgot/forgot.component';
import { RegistrationStepsComponent } from './registration-steps/registration-steps.component';
import { ActionsComponent } from './actions/actions.component';

export const PagesState: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        // canActivate: [UserRouteAccessService],
    },
    {
        path: 'registration',
        component: RegistrationStepsComponent
    },
    {
        path: 'forgot-password',
        component: ForgotComponent,
    },
    {
        path: 'actions',
        component: ActionsComponent,
    }
];

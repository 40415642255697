import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FrameShowService {
    phaser3 = null;
    phaser2 = null;
    public show: Subject<any> = new Subject();
    public loading: Subject<any> = new Subject();
    public background: Subject<any> = new Subject();
    continentCompletion: Subject<any> = new Subject();
    phaser: Subject<any> = new Subject();
    constructor() {
        this.phaser2 = window['Phaser'];
    }
    useV3() {
        if (this.phaser3) {
            if (window['Phaser'].VERSION !== this.phaser3.VERSION) {
                window['Phaser'] = this.phaser3;
            }
        } else {
            this.loadScript();
            setTimeout(() => {
                this.phaser3 =  window['Phaser'];
            }, 1500);
        }
    }
    useV2() {
        if (window['Phaser'].VERSION !== this.phaser2.VERSION) {
            window['Phaser'] = this.phaser2;
        }
    }

    public loadScript() {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
                isFound = true;
            }
        }

        if (!isFound) {
            const dynamicScripts = [
                'assets/games/quiz/phaser.min.js',
            ];

            for (let i = 0; i < dynamicScripts.length; i++) {
                const node = document.createElement('script');
                node.src = dynamicScripts [i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(node);
            }

        }
    }

    public callAfterDone(callback) {
        if (this.phaser3 && typeof callback === 'function') {
            callback();
        } else {
            setTimeout(() => {
                this.callAfterDone(callback);
            }, 50);
        }
    }
}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { HttpService } from '../../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
      private fireAuth: AngularFireAuth,
      private http: HttpService
  ) { }

  saveProfile(userData): Observable<any> {
      return this.http.postRequest('adminUpdateProfile', userData);
  }
}

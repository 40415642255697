import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
    loaderStatus: boolean = false;

    actionCode = "";

    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private meta: Meta
    ) {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
    }

    ngOnInit() {
        this.loaderStatus = true;

        this.route.queryParams.subscribe(params => {
            if (params['mode'] != "verifyEmail") {
                this.router.navigate(['/login'])
            }

            if (params['oobCode'] == undefined || params['oobCode'] == null) {
                this.router.navigate(['/login'])
            }

            this.auth.handleEmailVerification(params['oobCode']).then(x => {
                if (x) {
                    this.loaderStatus = false;
                } else {
                    this.router.navigate(['/login'])
                }
            });
        });

        localStorage.removeItem('currentUser');
    }

    gotoSignIn() {
        this.router.navigate(['/login'])
    }
}

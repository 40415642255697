import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AssignmentService } from "src/app/shared/assignment.service";
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as uuid from 'uuid';
import { ActivityService } from './activity.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class PointsMechanismService {
	public userInfo: any;
	private startTime: any;
	private startTimeTS: any;
	private adminUserData: any;
	private isAssignment = false;
	public id: string;
	public activityId: string;

	public isLastActivity: boolean;
	public nextActivityType: string;

	private sessionId: string;
	public timerStarted = false;

	showCongratsAssignment = false;
	congratsAssignmentType = 0;
	congratsAssignmentLesson = 1;

	getUserInfo: EventEmitter<any> = new EventEmitter();

	constructor(
		private router: Router,
		private http: HttpClient,
		public assignmentService: AssignmentService,
		private datePipe: DatePipe,
		private activityService: ActivityService,
		private auth: AuthService
	) {
		this.auth.onUserUpdate.subscribe(userData => {
			if (userData != null && userData) this.loadChildData(userData, true);
		}, error => {
			console.log('error', error);
		});
	}

	loadChildData(userData, skipCheck = false) {
		this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

		this.userInfo = userData;
		this.getUserInfo.emit(this.userInfo);

		this.isAssignment = false;

		for (let i = 0; i < this.assignmentService.list.length; i++) {
			if (this.assignmentService.list[i].assignment.lesson == this.id && this.assignmentService.list[i].gameId == this.activityId) {
				this.isAssignment = true;
				break;
			}
		}

		if (!skipCheck) {
			setTimeout(() => {
				if (!this.adminUserData && !this.isAssignment && (!this.userInfo.activities.hasOwnProperty(this.id) || !this.userInfo.activities[this.id].hasOwnProperty(this.activityId))) {
					this.router.navigate(['/game']);
				}
			}, 30000);
		}
	}

	async start(id, activityId) {
		this.id = id;
		this.activityId = activityId;

		this.setTimer();

		let user = JSON.parse(localStorage.getItem('currentUser'));
		let userUid = user.uid;
		let adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
		if (adminUserData) {
			userUid = user.email;
		}

		this.loadChildData(this.auth.userData);

		var serverTime = await this.getServerTime().toPromise();

		this.startTime = new Date(serverTime.time);
		this.startTimeTS = serverTime.time / 1000;

		this.getActivityType({ zoneId: this.id, activityId: (parseInt(activityId) + 1).toString() }).subscribe(w => {
			if (w.activityType) {
				this.isLastActivity = false;
				this.nextActivityType = w.activityType;
			} else {
				this.isLastActivity = true;
			}
		}, () => {
			this.isLastActivity = false;

			this.router.navigate(['/game/']);
		});

		var activityData = await this.getActivityType({ zoneId: this.id, activityId: (parseInt(this.activityId)).toString() }).toPromise();

		this.congratsAssignmentLesson = activityData.order;
	}

	getActivityType(data): Observable<any> {
		return this.http.post<any>(environment.firebaseFunctionUrl + 'getActivityType', data, {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			})
		});
	}

	collectPointF(data): Observable<any> {
		return this.http.post<any>(environment.firebaseFunctionUrl + 'collectPoint', data, {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			})
		});
	}

	getServerTime(): Observable<any> {
		return this.http.get<any>(environment.firebaseFunctionUrl + 'getCurrentTime', {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			})
		});
	}

	async collectPoint(points: number, type?: string) {
		const adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
		if (adminUserData) {
			return false;
		}

		let today: any = new Date();
		let date = (today - this.startTime);
		let minutes = Math.round((date / 1000) / 60);

		const currentGameId = this.id;
		const currentActivityId = this.activityId;

		const list = this.assignmentService.list.filter(item => { return !item.done && item.assignment.lesson == currentGameId && item.gameId == currentActivityId; });

		var isAssignment = false;

		if (list.length) {
			isAssignment = true;
			this.assignmentService.updateToDone(list[0].id, date / 1000).then(async res => {
				await this.assignmentService.load(this.userInfo.uid, true);
			}).catch(err => {
				console.log('err', err);
			});

			//local update to done
			for (let index in this.assignmentService.list) {
				if (this.assignmentService.list[index].id == list[0].id) {
					this.assignmentService.list[index].done = true;
				}
			}

			if (this.isLastActivity) {
				this.showCongratsAssignment = true;
				this.congratsAssignmentType = 0;

				var allDone = true;

				for (var i = 0; i < this.assignmentService.list.length; i++) {
					if (!this.assignmentService.list[i].done) {
						allDone = false;
						break;
					}
				}

				if (allDone) {
					this.congratsAssignmentType = 1;
				}

				localStorage.setItem("showAssignmentPopUp", this.congratsAssignmentType == 0 ? ("Lesson " + this.congratsAssignmentLesson.toString()) : "AllDone");
			}
		}

		this.collectPointF({
			startTime: this.startTimeTS,
			id: currentGameId,
			activityId: currentActivityId,
			isLastActivity: this.isLastActivity,
			uid: this.userInfo.uid,
			points,
			type,
			assignments: list.length ? this.assignmentService.list : null,
			isAssignment: isAssignment
		}).subscribe(async w => {
			if (!list.length) {
				this.userInfo = w.data;

				let currentWeek = this.datePipe.transform(today, 'w');
				let currentYear = this.datePipe.transform(today, 'y');

				if (!this.userInfo.weeks) {
					this.userInfo.weeks = [];
				}

				let currentWeekElement = this.userInfo.weeks.find(element => element.week == currentWeek && element.year == currentYear);

				if (currentWeekElement) {
					currentWeekElement.totalMinutes += minutes;
					currentWeekElement.totalScore += points;
				} else {
					this.userInfo.weeks.push({
						week: currentWeek,
						year: currentYear,
						totalMinutes: minutes,
						totalScore: points
					});
				}

				if (!this.userInfo.activities[currentGameId].weeks) {
					this.userInfo.activities[currentGameId].weeks = [];
				}

				currentWeekElement = this.userInfo.activities[currentGameId].weeks.find(element => element.week == currentWeek && element.year == currentYear);

				if (currentWeekElement) {
					currentWeekElement.totalMinutes += minutes;
					currentWeekElement.totalScore += points;
				} else {
					this.userInfo.activities[currentGameId].weeks.push({
						week: currentWeek,
						year: currentYear,
						totalMinutes: minutes,
						totalScore: points
					});
				}

				await this.auth.updateSelfData({ collectionID: "userData", data: { weeks: this.userInfo.weeks, activities: this.userInfo.activities } }).toPromise();
			}
		});
	}

	backToActivityList() {
		this.router.navigate(['/game/activity-list', this.id]);
	}

	nextActivity() {
		if (this.isLastActivity) {
			//let zone = this.id.split('-')[1];
			const params = this.id.split('-');
			let zone = params[1];
			let zoneKey = 'zone';
			if (params[0] === '3') {
				zoneKey = 'africa-';
			}
			if (params[0] === '4') {
				zoneKey = 'south-america-';
			}
			if (params[0] === '2' && zone === '1') {
				zone = '5';
			} else if (params[0] === '2' && zone === '2') {
				zone = '6';
			} else if (params[0] === '2' && zone === '3') {
				zone = '7';
			}

			this.router.navigate(['/game/' + zoneKey + zone]);
		} else {
			this.router.navigate(['/game/activity-' + this.nextActivityType, this.id, parseInt(this.activityId) + 1 + '']);
		}
	}

	saveToGallery(data) {
		this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(async aData => {
			data.countryTitle = aData.countryTitle;
			if (!this.userInfo.gallery || this.userInfo.gallery.length <= 0) {
				this.userInfo.gallery = [];
			}

			let index = this.userInfo.gallery.findIndex(element => element.id == data.id && element.index == data.index);
			if (index > -1) {
				this.userInfo.gallery[index] = data;
			} else {
				this.userInfo.gallery.push(data);
			}

			await this.auth.updateSelfData({ collectionID: "userData", data: { gallery: this.userInfo.gallery } }).toPromise();
		});
	}

	setTimer() {
		this.sessionId = uuid.v4();

		sessionStorage.setItem(this.sessionId, (Math.trunc(new Date().getTime() + (600 * 500))).toString());

		setTimeout(() => {
			this.checkActivity();
		}, 10000);
		this.timerStarted = true;
	}

	public updateTimer() {
		if (this.timerStarted) {
			sessionStorage.setItem(this.sessionId, (Math.trunc(new Date().getTime() + (600 * 500))).toString());
		}
	}

	checkActivity() {
		var startTimeL = parseInt(sessionStorage.getItem(this.sessionId));

		if (startTimeL <= new Date().getTime()) {
			sessionStorage.removeItem(this.sessionId);
			this.setTimer();
			window.location.reload();
		} else {
			setTimeout(() => {
				this.checkActivity();
			}, 10000);
		}
	}

	isIOS() {
		let userAgent = navigator.userAgent;
		if (/iPad|iPhone|iPod|Macintosh/.test(userAgent)) {
			return true;
		}

		return false;
	}
}

import { Component, OnInit } from '@angular/core';
import { ErrorMatcher } from 'src/app/shared/error.matcher';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    matcher = new ErrorMatcher();
    resetPassrodForm = new FormGroup({
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(8)
        ]),
        rePassword: new FormControl('', [
            Validators.required
        ])
    });

    loaderStatus = true;
    newPasswordProcess = true;

    userEmail = "";
    actionCode = "";

    get password() { return this.resetPassrodForm.get('password'); }
    get rePassword() { return this.resetPassrodForm.get('rePassword'); }

    constructor(
        private router: Router,
        private auth: AuthService,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params['oobCode'] == undefined || params['oobCode'] == null) {
                this.router.navigate(['/login'])
            }

            this.actionCode = params['oobCode'];

            this.auth.handleResetPasswordEmail(this.actionCode).then(x => {
                if (x != "Error") {
                    this.userEmail = x;
                    this.loaderStatus = false;
                } else {
                    this.router.navigate(['/login'])
                }
            }).catch(w => {
                this.router.navigate(['/login'])
            });
        });

        this.auth.logout();
    }

    resetPassword() {
        if (this.password.value == this.rePassword.value) {
            this.rePassword.setErrors(null);
        }
        else {
            this.rePassword.setErrors([{ 'passwordMismatch': true }]);
            return;
        }

        this.loaderStatus = true;
        var newPassword = this.password.value;

        this.auth.handleResetPassword(this.actionCode, newPassword).then(x => {
            if (x) {
                this.loaderStatus = false;
                this.newPasswordProcess = false;
            } else {
            }
        }).catch(w => {
            this.router.navigate(['/login'])
        });
    }

    passwordChanged() {
        if (this.password.value == this.rePassword.value) {
            this.rePassword.setErrors(null);
        }
        else {
            this.rePassword.setErrors([{ 'passwordMismatch': true }]);
        }
    }

    gotoSignIn() {
        this.router.navigate(['/login'])
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    constructor() {
    }

    public loaderStatus: Subject<boolean> = new Subject();
}

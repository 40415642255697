import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FirebaseModule } from '../core/firebase/firebase.module';
import { RouterModule } from '@angular/router';
import { PagesState } from './pages.route';
import { MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, MatSelectModule, MatRadioModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotComponent } from './forgot/forgot.component';
import { RegistrationStepsComponent } from './registration-steps/registration-steps.component';
import { RegistrationStepComponent } from './registration-steps/steps/registration-step.component';
// import { ChildernAddStepComponent } from './registration-steps/steps/childern-add-step.component';
// import { BillingStepComponent } from './registration-steps/steps/billing-step.component';
// import { SuccessStepComponent } from './registration-steps/steps/success-step.component';
import { VerifyComponent } from './actions/verify/verify.component';
import { ActionsComponent } from './actions/actions.component';
import { ResetPasswordComponent } from './actions/password-reset/password-reset.component';

@NgModule({
    declarations: [
        LoginComponent,
        ResetPasswordComponent,
        VerifyComponent,
        ActionsComponent,
        ForgotComponent,
        RegistrationStepsComponent,
        RegistrationStepComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(PagesState),
        FirebaseModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatRadioModule
    ]
})
export class PagesModule { }

import { Injectable } from '@angular/core';
import { ActivityService } from './activity.service';

@Injectable({
	providedIn: 'root'
})
export class AssignmentService {
	public list = [];
	public lessonsList = [];
	public lessonsListView = [];
	public assignments = [];
	public assignmentsALL = [];
	public assignmentsItemsALL = [];
	private assignmentIds = [];
	public loaded = false;
	public isFirst = true;
	public firstCallback = null;
	public loading = false;
	public showAssignmentPopUp = false;
	public openAssignmentPopup = null;
	public canAccess = {};

	constructor(
		private activityService: ActivityService
	) { }

	reset() {
		this.list = [];
		this.lessonsList = [];
		this.lessonsListView = [];
		this.assignments = [];
		this.assignmentsALL = [];
		this.assignmentsItemsALL = [];
		this.assignmentIds = [];
		this.showAssignmentPopUp = false;
		this.loaded = false;
		this.isFirst = true;
		this.loading = true;
		this.firstCallback = null;
		this.openAssignmentPopup = null;
		this.canAccess = {};
	}

	updateToDone(id, time) {
		return new Promise<boolean>((resolve, reject) => {
			this.activityService.checkToken(async () => {
				await this.activityService.updateAssignmentItem({ id, time }).toPromise();

				resolve(true);
			});
		});
	}

	canAccessAnotherGames() {
		for (let index in this.list) {
			if (!this.list[index].done) {
				return false;
			}
		}

		return true;
	}

	load(uid, skipPopUp = false): Promise<object> {
		return new Promise<object>(async (resolve, reject) => {
			const assignmentData = await this.activityService.getAssignmentData().toPromise();

			this.lessonsList = [];
			this.lessonsListView = [];
			this.assignments = [];
			this.assignmentIds = [];
			this.list = [];

			const assignmentItemIds = [];
			let schoolId = 0;

			if (assignmentData.result != undefined && !assignmentData.result) {
				this.loading = false;
				reject({});
			} else {
				this.assignmentsALL = assignmentData.assignments;
				this.assignmentsItemsALL = assignmentData.assignmentItems;

				assignmentData.assignmentItems.forEach((assignmentItemDoc: any) => {
					assignmentItemIds.push(assignmentItemDoc.assignmentId);
					schoolId = assignmentItemDoc.school;
				});

				assignmentData.assignments.forEach((assignmentDoc: any) => {
					const nowTime = (new Date()).getTime() / 1000;

					if (assignmentItemIds.indexOf(assignmentDoc.id) > -1 && nowTime > assignmentDoc.startDate.seconds && nowTime < assignmentDoc.endDate.seconds) {
						this.assignments.push({ id: assignmentDoc.id, ...assignmentDoc });
						this.assignmentIds.push(assignmentDoc.id);
					}
				});

				let lessonsList = [];

				assignmentData.assignmentItems.forEach((assignmentItemDoc: any) => {
					if (this.assignmentIds.indexOf(assignmentItemDoc.assignmentId) > -1) {
						const assignmentData = this.getAssignmentById(assignmentItemDoc.assignmentId);
						this.list.push({ id: assignmentItemDoc.id, assignment: assignmentData, ...assignmentItemDoc });
						let lessonName = assignmentData.title.split(' - ')[1];

						if (lessonName === undefined) lessonName = assignmentData.title;

						if (lessonsList.indexOf(lessonName) === -1) {
							lessonsList.push(lessonName);

							var allAssginmentItems = this.assignmentsItemsALL.filter(x => x.assignmentId == assignmentData.id);

							var allDone = true;

							if (assignmentItemDoc.gameType != "quiz") {
								for (var i = 0; i < allAssginmentItems.length; i++) {
									if (!allAssginmentItems[i].done) {
										allDone = false;
										break;
									}
								}
							} else {
								allDone = assignmentItemDoc.done;
							}

							this.lessonsList.push({ id: assignmentItemDoc.id, lessonName, assignment: assignmentData, ...assignmentItemDoc, allDone });
						}

						const lesson = assignmentData.lesson.split(':');

						if (!this.canAccess[assignmentData.level]) {
							this.canAccess[assignmentData.level] = {};
						}

						if (!this.canAccess[assignmentData.level][lesson[0]]) {
							this.canAccess[assignmentData.level][lesson[0]] = {};
						}

						this.canAccess[assignmentData.level][lesson[0]][assignmentItemDoc.gameType] = true;
						this.canAccess[assignmentData.level][lesson[0]][assignmentItemDoc.gameId] = assignmentItemDoc.done;
					}
				});

				this.lessonsListView = this.lessonsList.filter(x => x.allDone == false);

				// this.lessonsListView = this.lessonsListView.sort((a, b) => {
				// 	const aWords = a.assignment.title.split(' ');
				// 	const bWords = b.assignment.title.split(' ');
				// 	const aNum = parseInt(aWords[1]);
				// 	const bNum = parseInt(bWords[1]);

				// 	if (aWords[0] < bWords[0]) {
				// 		return -1;
				// 	} else if (aWords[0] > bWords[0]) {
				// 		return 1;
				// 	} else {
				// 		return aNum - bNum;
				// 	}
				// });

				this.lessonsListView = this.lessonsListView.sort((a, b) => {
					return this.numberSort(b.assignment.endDate.seconds, a.assignment.endDate.seconds);;
				});

				this.loaded = true;

				if (!skipPopUp) {
					if (!this.canAccessAnotherGames() && this.isFirst && this.firstCallback) {
						this.isFirst = false;
						this.firstCallback();
					}
				}

				this.loading = false;

				for (let index in this.list) {
					if (!this.list[index].done) {
						this.showAssignmentPopUp = true;
						break;
					} else {
						this.showAssignmentPopUp = false;
					}
				}

				resolve({ result: true, list: this.list });
			}
		});
	}

	private getAssignmentById(id) {
		for (let index in this.assignments) {
			if (id == this.assignments[index].id) {
				return this.assignments[index];
			}
		}

		return false;
	}

	numberSort(a, b) {
		return a - b;
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}

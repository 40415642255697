import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrameShowService } from '../../shared/FrameShowService';
import { AssignmentService } from "src/app/shared/assignment.service";
import { ActivityService } from 'src/app/shared/activity.service';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-activity-list',
	templateUrl: './activity-list.component.html'
})
export class ActivityListComponent implements OnInit, OnDestroy, AfterViewInit {
	userActivities: any = {};
	id: string;

	activity: any;
	activities: any;
	isAdmin: boolean = false;

	playFirstActivitySelection = false;

	loadedAssignmentStatus = false;

	titles: any = {};

	background = '';

	routeData: any;

	userData = null;
	protected userSubscription: Subscription;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private frame: FrameShowService,
		private activityService: ActivityService,
		public assignmentService: AssignmentService,
		private auth: AuthService
	) {
		this.auth.onUserUpdate.subscribe(userData => {
			this.loadChildData(userData);
		}, error => {
			console.log('error', error);
		});
	}

	ngOnInit() {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		const adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
		this.isAdmin = !!adminUserData;

		this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
			if (userData != null && userData) this.loadChildData(userData);
		}, error => {
			console.log('error', error);
		});

		this.activatedRoute.params.subscribe((data) => {
			this.routeData = data;

			if (data && data.id) {
				this.background = this.getBg(data['id']);
				this.id = data.id;

				const levelNo = this.id.split('-')[0];

				if (!this.assignmentService.canAccessAnotherGames() && Object.keys(this.assignmentService.canAccess).length) {
					if (!(this.assignmentService.canAccess[levelNo] && this.assignmentService.canAccess[levelNo][this.id])) {
						this.backtoMap();

						setTimeout(() => {
							const assignmentBell = <HTMLAudioElement>document.querySelector('.assignment-bell-warning');
							assignmentBell.click();
						}, 1500);
					}
				}
			}
		});
	}

	loadChildData(userData) {
		this.userData = userData;

		if (this.routeData) {
			this.userActivities = userData && userData.activities && userData.activities[this.routeData.id] ? userData.activities[this.routeData.id] : {};
			this.setbuttonEvents();
		} else {
			setTimeout(() => {
				this.loadChildData(this.userData);
			}, 100);
		}
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.setbuttonEvents();
		setTimeout(() => {
			this.activityService.getActivityData({ zoneId: this.id }).subscribe(aData => {
				this.activities = aData.activityData || {};
				this.titles = aData;
				this.frame.background.next(this.id);
				this.setbuttonEvents();

				var counter = 0;

				for (let i = 0; i < this.activities.length; i++) {
					var activityData = this.activities[i];
					if (this.userActivities[activityData.orderNumber]) {
						++counter;
					}
				}
				if (counter < 2) {
					this.playFirstActivitySelection = true;
				}

				if (this.isAdmin) {
					this.playFirstActivitySelection = false;
				}

				const levelNo = this.id.split('-')[0];

				setTimeout(() => {
					if (this.assignmentService.canAccess[levelNo] && this.assignmentService.canAccess[levelNo][this.id]) {
						if (!this.userActivities['1']) {
							this.userActivities['1'] = {};
						}
					}
					this.loadedAssignmentStatus = true;
				}, 2500);
			});
		}, 500);
	}

	getBg(id) {
		return 'assets/images/activity-list/bg-' + (id.split('-')[0]) + '.jpg';
	}

	setbuttonEvents() {
		setTimeout(() => {
			var buttons = document.querySelectorAll('button');
			for (var i = 0; i < buttons.length; i++) {
				buttons[i].addEventListener('click', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('buttonClick');
					if (audio) audio.play();
				});
				buttons[i].addEventListener('mouseover', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('buttonHover');
					if (audio) audio.play();
				});
			}
		}, 300);
	}

	previousState() {
		window.history.back();
	}

	backtoMap() {
		/*let id = this.id.split('-')[1];
		this.router.navigate(['/game/zone' + id]);*/
		const id = this.id.split('-')[1];
		let zoneKey = 'zone';
		if (this.id.split('-')[0] == '3') {
			zoneKey = 'africa-';
		} else if (this.id.split('-')[0] == '4') {
			zoneKey = 'south-america-';
		} else if (this.id.split('-')[0] == '5') {
			zoneKey = 'north-america-';
		}

		if (this.id.split('-')[0] === '2' && id === '1') {
			this.router.navigate(['/game/zone5']);
			return;
		} else if (this.id.split('-')[0] === '2' && id === '2') {
			this.router.navigate(['/game/zone6']);
			return;
		} else if (this.id.split('-')[0] === '2' && id === '3') {
			this.router.navigate(['/game/zone7']);
			return;
		}

		this.router.navigate(['/game/' + zoneKey + id]);
	}

	mouseEnter() {
		let audio = <HTMLAudioElement>document.getElementById('activityHover');
		audio.pause();
		audio.currentTime = 0;
		if (audio) audio.play();
	}

	activityClick(type, id, order) {
		if (!this.assignmentService.canAccessAnotherGames() && Object.keys(this.assignmentService.canAccess).length) {
			const levelNo = id.split('-')[0];
			if (
				this.assignmentService.canAccess[levelNo] &&
				this.assignmentService.canAccess[levelNo][id] &&
				this.assignmentService.canAccess[levelNo][id][type]
			) {
				this.goActivityClick(type, id, order);
			} else {
				const assignmentBell = <HTMLAudioElement>document.querySelector('.assignment-bell');
				assignmentBell.click();
			}
		} else {
			this.goActivityClick(type, id, order);
		}
	}

	private goActivityClick(type, id, order) {
		let audio = <HTMLAudioElement>document.getElementById('activityClick');
		if (audio) audio.play();
		setTimeout(() => {
			this.router.navigate(['/game/activity-' + type, id, order]);
		}, 1000);
	}

	checkAssignment(activity) {
		if (activity.orderNumber == 1) return false;

		if (!this.assignmentService.showAssignmentPopUp) return false;

		const levelNo = this.id.split('-')[0];

		let can = Object.keys(this.assignmentService.canAccess).length > 0 ? !(this.assignmentService.canAccess[levelNo] && this.assignmentService.canAccess[levelNo][this.id] && this.assignmentService.canAccess[levelNo][this.id][activity.orderNumber - 1]) : false;

		return can;
	}
}

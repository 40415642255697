import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, HostListener } from '@angular/core';
import { fromEvent } from 'rxjs';



@Component({
	selector: 'app-board',
	templateUrl: './board.component.html'
})
export class BoardComponent implements OnInit, AfterViewInit {

	@ViewChild('myCanvas', { static: false }) myCanvas: any;
	public context: CanvasRenderingContext2D;

	_lineWidth: number = 18;
	brushPath = " c 1.99413,-0.51315 3.72489,0.99425 5.59864,0.36349 0.45902,-0.16036 1.00285,1.08289 0.26373,1.42236 -0.0677,0.28865 0.84998,0.0423 0.9177,-4.8e-4 0.88796,-0.63076 0.0477,-0.1598 -0.13291,1.36899 -0.0151,0.1176 0.0527,0.29934 0.26338,0.2352 -0.0999,1.93059 -2.06986,0.5185 -4.15637,1.56029 1.42976,-0.0534 2.77675,1.29359 4.45483,-0.0214 -0.77508,1.44326 -0.77508,1.25082 -3.9958,1.18668 -1.36956,-0.0214 -2.79179,0.16036 -4.49246,0.31003 1.06103,0.60938 1.72097,0.66527 2.62398,0.22694 1.06856,-0.52385 1.61559,0.30692 2.73681,0.0717 0.0376,-0.0107 0.17309,0.41695 0.15803,0.62007 -0.0376,0.38487 -0.26337,0.0962 -0.40636,0.14967 -1.12875,0.40625 -1.8639,0.93322 -2.97761,0.74078 -0.61705,-0.1069 -1.11897,0.46797 -1.71345,0.76731 1.39966,0 2.52764,-0.4761 3.92731,-0.4333 0.28594,0.0107 0.82775,-0.3528 0.82775,0.43832 0,0.71629 -0.48914,0.33142 -0.79013,0.43833 -2.12959,0.78043 -4.42046,0.74565 -6.61305,0.44667 -0.68818,-0.0938 -1.22098,-0.10067 -1.98805,-0.69431 0.0828,-0.4597 1.71558,-1.4798 1.50482,-1.46911 -0.036,-0.29221 -1.80168,0.40538 -0.39972,-1.20595 0.30488,-0.35041 -0.66571,-0.66309 -0.49124,-1.04284 0.29714,-0.64676 1.19104,-0.20038 1.25971,-0.75328 0.0343,-0.27618 -1.54121,0.13528 -1.51745,-0.12003 0.0412,-0.44261 -0.50429,-0.9127 -0.43358,-1.22761 0.12113,-0.53944 1.03197,-0.42421 1.03197,-0.42421 l -1.11464,-0.68202 c 0.28233,-0.87501 2.03333,-1.17408 2.03333,-1.17408 0,0 -1.82646,0.16932 -1.59102,0.0497 1.26093,-1.26754 3.86114,-0.82789 5.21185,-1.14796 z";

	@Input('lineWidth')
	set lineWidth(value)
	{
		this._lineWidth = value;
		switch (value) {
			case 24:
				this.brushPath = " c 2.85768,-0.76659 5.33793,1.48531 8.0231,0.54301 0.65779,-0.23955 1.4371,1.61772 0.37793,2.12484 -0.097,0.43122 1.21804,0.0632 1.31509,-7e-4 1.27248,-0.94229 0.0684,-0.23872 -0.19046,2.04511 -0.0215,0.17569 0.0756,0.44718 0.37742,0.35136 -0.1432,2.88409 -2.96618,0.77459 -5.95624,2.33091 2.04891,-0.0798 3.9792,1.93247 6.38396,-0.0319 -1.11072,2.15607 -1.11072,1.86858 -5.72614,1.77277 -1.96265,-0.032 -4.00077,0.23955 -6.4379,0.46314 1.5205,0.91035 2.46623,0.99384 3.76028,0.33903 1.5313,-0.78257 2.31521,0.4585 3.92198,0.10711 0.0539,-0.016 0.24803,0.62288 0.22646,0.92631 -0.0539,0.57496 -0.37743,0.14372 -0.58234,0.2236 -1.61755,0.60688 -2.67105,1.39412 -4.26704,1.10664 -0.88426,-0.15969 -1.60354,0.69909 -2.45545,1.14627 2.00576,0 3.62222,-0.71124 5.62799,-0.64731 0.40977,0.016 1.1862,-0.52704 1.1862,0.65482 0,1.07006 -0.70094,0.49509 -1.13227,0.65481 -3.0518,1.16587 -6.33472,1.11392 -9.47679,0.66728 -0.98619,-0.14013 -1.74972,-0.1504 -2.84896,-1.03723 0.11867,-0.68674 2.4585,-2.21065 2.15647,-2.19469 -0.0516,-0.43652 -2.58189,0.6056 -0.57282,-1.80155 0.4369,-0.52347 -0.95399,-0.99058 -0.70397,-1.55788 0.42581,-0.9662 1.70682,-0.29936 1.80522,-1.12532 0.0491,-0.41258 -2.20862,0.20208 -2.17458,-0.17932 0.0591,-0.66121 -0.72266,-1.36346 -0.62132,-1.83391 0.17358,-0.80586 1.47886,-0.63372 1.47886,-0.63372 l -1.59734,-1.01886 c 0.40459,-1.30717 2.91386,-1.75394 2.91386,-1.75394 0,0 -2.61741,0.25294 -2.28001,0.0743 1.80697,-1.89356 5.53318,-1.23678 7.46881,-1.71493 z"
				break;
			case 30:
				this.brushPath = " c 4.06988,-1.04023 7.60224,2.01548 11.42642,0.73683 0.93683,-0.32506 2.04671,2.1951605 0.53825,2.8833005 -0.13822,0.58514 1.73473,0.0857 1.87294,-8.7e-4 1.81226,-1.27864 0.0974,-0.32393 -0.27125,2.77511 -0.0307,0.2384 0.10757,0.6068 0.53752,0.47678 -0.20395,3.91356 -4.22441,1.05108 -8.48282,3.16292 2.91803,-0.10822 5.66713,2.62227 9.09197,-0.0433 -1.58188,2.92568 -1.58188,2.53557 -8.15511,2.40556 -2.79519,-0.0434 -5.69786,0.32506 -9.1688,0.62845 2.16548,1.23531 3.51239,1.34859 5.35535,0.46005 2.18086,-1.06191 3.2973,0.62217 5.58565,0.14535 0.0768,-0.0217 0.35324,0.84521 0.32253,1.25696 -0.0768,0.78018 -0.53754,0.19501 -0.82936,0.3034 -2.3037,0.82351 -3.80409,1.89176 -6.0771,1.50166 -1.25935,-0.2167 -2.28373,0.94863 -3.49702,1.55543 2.8566,0 5.15874,-0.96511 8.01534,-0.87836 0.58358,0.0217 1.68937,-0.71517 1.68937,0.88855 0,1.45201 -0.99828,0.67182 -1.61257,0.88855 -4.34634,1.58202 -9.02185,1.51152 -13.49676,0.90545 -1.40452,-0.19015 -2.49193,-0.20407 -4.05746,-1.40746 0.16901,-0.93187 3.50137,-2.99973 3.07122,-2.97807 -0.0734,-0.59235 -3.6771,0.82175 -0.81581,-2.44463 0.62224,-0.71033 -1.35865,-1.34417 -1.00258,-2.11397 0.60644,-1.31107 2.43083,-0.4062 2.57098,-1.527 0.07,-0.55983 -3.14549,0.27423 -3.09701,-0.24331 0.0841,-0.89724 -1.02921,-1.85015 -0.88489,-2.48853 0.24721,-1.09351 2.10618,-0.85993 2.10618,-0.85993 l -2.27492,-1.38254 c 0.57622,-1.77376 4.1499,-2.38001 4.1499,-2.38001 0,0 -3.72769,0.34322 -3.24717,0.10082 2.57347,-2.5694605 7.8803,-1.6782405 10.63701,-2.3270705 z"
				break;
			default:
				this.brushPath = " c 1.99413,-0.51315 3.72489,0.99425 5.59864,0.36349 0.45902,-0.16036 1.00285,1.08289 0.26373,1.42236 -0.0677,0.28865 0.84998,0.0423 0.9177,-4.8e-4 0.88796,-0.63076 0.0477,-0.1598 -0.13291,1.36899 -0.0151,0.1176 0.0527,0.29934 0.26338,0.2352 -0.0999,1.93059 -2.06986,0.5185 -4.15637,1.56029 1.42976,-0.0534 2.77675,1.29359 4.45483,-0.0214 -0.77508,1.44326 -0.77508,1.25082 -3.9958,1.18668 -1.36956,-0.0214 -2.79179,0.16036 -4.49246,0.31003 1.06103,0.60938 1.72097,0.66527 2.62398,0.22694 1.06856,-0.52385 1.61559,0.30692 2.73681,0.0717 0.0376,-0.0107 0.17309,0.41695 0.15803,0.62007 -0.0376,0.38487 -0.26337,0.0962 -0.40636,0.14967 -1.12875,0.40625 -1.8639,0.93322 -2.97761,0.74078 -0.61705,-0.1069 -1.11897,0.46797 -1.71345,0.76731 1.39966,0 2.52764,-0.4761 3.92731,-0.4333 0.28594,0.0107 0.82775,-0.3528 0.82775,0.43832 0,0.71629 -0.48914,0.33142 -0.79013,0.43833 -2.12959,0.78043 -4.42046,0.74565 -6.61305,0.44667 -0.68818,-0.0938 -1.22098,-0.10067 -1.98805,-0.69431 0.0828,-0.4597 1.71558,-1.4798 1.50482,-1.46911 -0.036,-0.29221 -1.80168,0.40538 -0.39972,-1.20595 0.30488,-0.35041 -0.66571,-0.66309 -0.49124,-1.04284 0.29714,-0.64676 1.19104,-0.20038 1.25971,-0.75328 0.0343,-0.27618 -1.54121,0.13528 -1.51745,-0.12003 0.0412,-0.44261 -0.50429,-0.9127 -0.43358,-1.22761 0.12113,-0.53944 1.03197,-0.42421 1.03197,-0.42421 l -1.11464,-0.68202 c 0.28233,-0.87501 2.03333,-1.17408 2.03333,-1.17408 0,0 -1.82646,0.16932 -1.59102,0.0497 1.26093,-1.26754 3.86114,-0.82789 5.21185,-1.14796 z";
				break;
		}
	}
	


	@Input()
	bg: string;

	@Input()
	color: string = '#00ff00';


	mousedown: boolean;

	

	constructor() { }

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');

		this.myCanvas.nativeElement.width = 500;
		this.myCanvas.nativeElement.height = 400;

		this.context.beginPath();
		this.context.rect(0, 0, 500, 400);
		this.context.fillStyle = "#ffffff";
		this.context.fill();
		// Touch Events Handlers
		let draw = {
			started: false,
			start: function (evt) {
				this.context.beginPath();
				this.context.moveTo(
					evt.touches[0].pageX,
					evt.touches[0].pageY
				);

				this.started = true;

			},
			move: function (evt) {

				if (this.started) {
					this.context.lineTo(
						evt.touches[0].pageX,
						evt.touches[0].pageY
					);

					this.context.strokeStyle = "#000";
					this.context.lineWidth = 5;
					this.context.stroke();
				}

			},
			end: function (evt) {
				this.started = false;
			}
		};

		// Touch Events
		this.myCanvas.nativeElement.addEventListener('touchstart', draw.start, false);
		this.myCanvas.nativeElement.addEventListener('touchend', draw.end, false);
		this.myCanvas.nativeElement.addEventListener('touchmove', draw.move, false);



		// Disable Page Move
		document.body.addEventListener('touchmove', function (evt) {
			evt.preventDefault();
		}, false);
	}


	@HostListener('mousedown', ['$event'])
	onMousedown(evt) {
		if (this.context) {
			this.context.beginPath();			
			let brushPath = new Path2D("m "+evt.layerX+","+evt.layerY+' '+this.brushPath);
			this.context.fillStyle = this.color;			
			if(this.color === '#ffffff')
			{
				this.context.rect(evt.layerX, evt.layerY, this._lineWidth, this._lineWidth);
				this.context.fill();
			}
			else
			{
				this.context.fill(brushPath);
			}
			this.mousedown = true;
		}
	}

	@HostListener('mousemove', ['$event'])
	onMousemove(evt) {
		if (this.context && this.mousedown) {
			this.context.beginPath();			
			let brushPath = new Path2D("m "+evt.layerX+","+evt.layerY+' '+this.brushPath);
			this.context.fillStyle = this.color;			
			if(this.color === '#ffffff')
			{
				this.context.rect(evt.layerX, evt.layerY, this._lineWidth, this._lineWidth);
				this.context.fill();
			}
			else
			{
				this.context.fill(brushPath);
			}
			this.mousedown = true;
		}

	}

	@HostListener('mouseup', ['$event'])
	onMouseup(evt) {
		this.mousedown = false;
	}

	@HostListener('touchmove', ['$event'])
	handleTouch(evt) {
		evt.preventDefault();
		var touch = evt.touches[0] || evt.changedTouches[0];
		let cords = this.getOffset(touch);
		let brushPath = new Path2D("m"+cords.x+","+cords.y+' '+this.brushPath);
		this.context.beginPath();
		this.context.fillStyle = this.color;
		if(this.color === '#ffffff')
		{
			// this.context.arc(cords.x, cords.y, this._lineWidth / 2, 0, 2 * Math.PI, false);
			this.context.rect(cords.x, cords.y, this._lineWidth, this._lineWidth);
			this.context.fill();
		}
		else
		{
			this.context.fill(brushPath);
		}			
		return false;
	}

	getOffset(evt) {
		// console.log(evt);
		let el = evt.target,
			x = 0,
			y = 0;

		// while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		// 	x += el.offsetLeft - el.scrollLeft;
		// 	y += el.offsetTop - el.scrollTop;
		// 	el = el.offsetParent;
		// }
		// console.log(x);
		x = (evt.pageX - 353);
		y = (evt.pageY - 375) - evt.radiusY/2;

		return { x: x, y: y };
	}

}


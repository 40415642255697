import { Component, OnInit } from '@angular/core';
import { ErrorMatcher } from 'src/app/shared/error.matcher';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
    verify: boolean = false;
    verifyEmail: boolean = false;
    isParent: boolean = true;
    showSuccessPopUp: boolean = false;

    emailFormControl = new FormControl('', [
        Validators.required
    ]);
    passwordFormControl = new FormControl('', [
        Validators.required
    ]);

    matcher = new ErrorMatcher();

    loaderStatus: boolean = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private meta: Meta
    ) {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
    }

    ngOnInit() {
        localStorage.removeItem('currentUser');
    }

    requestReset() {
        this.auth.isParent(this.emailFormControl.value.trim()).then(parent => {
            this.isParent = true;
            this.loaderStatus = true;
            this.verify = false;
            this.verifyEmail = false;
            this.auth.sendPasswordResetEmail(this.emailFormControl.value.trim()).then(result => {
                this.successPopUp();
            }).catch(error => {
                if ((this.emailFormControl.value.trim() as string).indexOf("@") > 0) {
                    this.verifyEmail = true;
                } else {
                    this.verify = true;
                }
            }).finally(() => {
                this.loaderStatus = false;
            });
        }).catch(error => {
            this.isParent = false;
        });
    }

    close() {
        this.isParent = true;
    }

    successPopUp() {
        this.showSuccessPopUp = true;
    }

    closeSuccessPupUP() {
        this.router.navigate(['/login'])
    }
}

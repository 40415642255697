import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseModule } from './firebase/firebase.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FirebaseModule
  ]
})
export class CoreModule { }

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserRouteAccessService implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let match = state.url.includes('activity-');
        if (this.auth.isLoggedIn) {
            let userData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
            if (userData == null) userData = JSON.parse(localStorage.getItem('superAdmin_userData'));

            let user = JSON.parse(localStorage.getItem('currentUser'));
            let id = route.params.id;
            if (!user.email || userData.email) {
                return true;
            }
            else {
                this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }
        else {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}

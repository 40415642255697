import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LoginActionStatusService } from '../shared/actionStatus.service';
import { FrameShowService } from '../shared/FrameShowService';
import { AssignmentService } from "../shared/assignment.service";
import { PointsMechanismService } from '../shared/point-mechanism.service';
import { AuthService } from '../shared/auth.service';

@Component({
	selector: 'app-game',
	templateUrl: './game.component.html'
})
export class GameComponent implements OnInit, AfterViewInit {
	name: string;
	totalScore: number;

	userInfo: any;

	hasFrame = true;
	background: string;
	public completion: any = null;
	pay = false;
	phaser2 = null;
	phaser3 = null;

	isAdmin = false;
	today = new Date();
	isMobileApp = false;

	showAssignmentWarning = false;

	mousemoveCount = 33;

	showAssignmentPopup = false;
	showHeaderProfileBox = false;

	parentData: any = {};

	showAssignmentDone = false;
	assignmentDoneLesson = 0;
	assignmentDoneTitle = "";
	assignmentDoneLessonText = "";

	childs = [];

	skipLoadCount = 3;

	@ViewChild('game', { static: false }) game: ElementRef;

	@HostListener('mousemove', ['$event'])
	onMouseMove(e) {
		if (this.mousemoveCount == 33 || this.mousemoveCount <= 0) {
			this.pm.updateTimer();
			if (this.mousemoveCount <= 0) this.mousemoveCount = 33;
		} else {
			--this.mousemoveCount;
		}
	}

	constructor(
		private afs: AngularFirestore,
		private meta: Meta,
		private activatedRoute: ActivatedRoute,
		private frameService: FrameShowService,
		private pm: PointsMechanismService,
		public assignmentService: AssignmentService,
		private router: Router, private actionStatus: LoginActionStatusService,
		private auth: AuthService
	) {
		this.phaser2 = window['Phaser'];
		if (localStorage.getItem('mobile-app')) {
			const width = 980;
			const height = 600;
			const initViewport = (w, h) => {
				let h2 = w * window.outerHeight / window.outerWidth;
				h = h2 > 600 ? h2 : h;
				let scale = window.outerHeight / h;
				if (scale > 1) {
					scale = window.outerWidth / w;
				}
				this.meta.updateTag({ name: 'viewport', content: 'width=' + w + ', initial-scale=' + (scale > 1 ? 1 : scale) });
			};

			window.addEventListener("load", event => initViewport(width, height));
			window.addEventListener("resize", event => initViewport(width, height));
		} else {
			this.meta.updateTag({ name: 'viewport', content: 'width=1266, initial-scale=1' });
		}
		// this.meta.updateTag({ name: 'viewport', content: 'width=device-width, height:device-height, initial-scale=1' });
		this.frameService.background.subscribe(value => {
			this.background = '/assets/images/bg/bg-' + value + '.jpg';

			document.getElementById("bgID").style.backgroundImage = 'url("' + this.background + '")';
		});

		this.frameService.continentCompletion.subscribe(item => {
			this.completion = item;
		});

		/*this.assignmentService.openAssignmentPopup = () => {
			this.showAssignmentPopupClick();
		};*/
		this.assignmentService.firstCallback = () => {
			if (window.location.pathname != "/game/my-assignments") this.showAssignmentPopupClick();
		};
		if (this.assignmentService.load && !this.assignmentService.canAccessAnotherGames() && this.assignmentService.isFirst) {
			if (this.assignmentService.list.length) {
				this.assignmentService.isFirst = false;
				this.showAssignmentPopupClick();
			}
		}
	}

	ngOnInit() {
		this.auth.onUserUpdate.subscribe(userData => {
			if (userData != null && userData) this.loadChildData(userData);
		}, error => {
			console.log('error', error);
		});

		this.isMobileApp = !!localStorage.getItem('mobile-app');
		this.showHeaderProfileBox = this.isMobileApp && this.router.url == '/game/game/continents';

		this.router.events.subscribe(val => {
			if (val instanceof NavigationEnd) {
				this.checkUrl(val.url);
			}
		}, error => {
			console.log('error', error);
		});
		this.checkUrl(this.router.url);

		var assignmentData = localStorage.getItem("showAssignmentPopUp");

		if (assignmentData) {
			this.showAssignmentDone = true;
			if (assignmentData == "AllDone") {
				this.assignmentDoneLesson = -1;
				this.assignmentDoneTitle = "Well Done!";
			} else {
				this.assignmentDoneTitle = "Great job!";
				this.assignmentDoneLesson = 1;
				this.assignmentDoneLessonText = assignmentData;
			}

			localStorage.removeItem("showAssignmentPopUp");
		}

		this.activatedRoute.queryParams.subscribe(params => {
			if (params['paymentFalse']) {
				this.pay = true;
			}
		}, error => {
			console.log('error', error);
		});
	}

	loadChildData(userData) {
		const adminUserData = localStorage.getItem('quranera_admin_userData');
		if (adminUserData) {
			const aUserData = JSON.parse(adminUserData);
			this.isAdmin = ['admin', 'admin_teacher', 'teacher'].indexOf(aUserData.role) > -1;
		}

		if (!userData) {
			if (adminUserData) {
				userData = JSON.parse(adminUserData);
				userData.totalScore = 0;
				userData.name = userData.firstname + ' ' + userData.lastname;
				userData.activeLevel = '1-1-1';
				userData.avatar = 'b1';
			}
		}
		if (userData) {
			this.totalScore = userData.totalScore;
		}
		else {
			this.totalScore = 0;
		}

		this.afs.collection('parents').doc(userData.parentId).get().subscribe((data) => {
			this.parentData = data.data();

			this.afs.collection('userData', ref => ref.where('parentId', '==', userData.parentId)).valueChanges().subscribe(data => {
				this.childs = data;
			});
		});

		this.userInfo = userData;
		this.name = this.userInfo.name;
		if (this.name.length > 9) {
			this.name = this.name.substring(0, 9) + '.';
		}
		this.background = '/assets/images/bg/bg-' + userData.activeLevel + '.jpg';
		this.setHoverClickEvents();
	}

	ngAfterViewInit(): void {
		this.setHoverClickEvents();
		this.game.nativeElement.dispatchEvent(new Event('touchstart'));
	}

	toDateString(date: number) {
		return new Date(date * 1000).toLocaleDateString("ru-RU");
	}

	setHoverClickEvents() {
		setTimeout(() => {
			var buttons = document.querySelectorAll('.logout');
			for (var i = 0; i < buttons.length; i++) {
				buttons[i].addEventListener('click', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('menuButtonClick');
					if (audio)
						if (audio) audio.play();
				});
				buttons[i].addEventListener('mouseover', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('menuButtonHover');
					if (audio) {
						audio.currentTime = 0;
						if (audio) audio.play();
					}
				});
			}

			buttons = document.querySelectorAll('.profile-box .avatar');
			for (var i = 0; i < buttons.length; i++) {
				buttons[i].addEventListener('click', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('menuButtonClick');
					if (audio)
						if (audio) audio.play();
				});
				buttons[i].addEventListener('mouseover', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('menuButtonHover');
					if (audio) {
						audio.currentTime = 0;
						if (audio) audio.play();
					}
				});
			}

			buttons = document.querySelectorAll('.menu a');
			for (var i = 0; i < buttons.length; i++) {
				buttons[i].addEventListener('click', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('menuButtonClick');
					if (audio) {
						if (audio) audio.play();
					}
				});
				buttons[i].addEventListener('mouseover', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('menuButtonHover');
					if (audio) {
						audio.currentTime = 0;
						if (audio) audio.play();
					}
				});
			}
		}, 100)
	}

	private async checkUrl(url: string) {
		let match = url.includes('activity-');
		let free = url.includes('1-1-1');

		if (match && !free) {
			let isActiveUser = this.auth.isActiveUser;

			if (this.isAdmin || isActiveUser) {
				return true;
			}
			else {
				const adminUserData = localStorage.getItem('quranera_admin_userData');
				if (adminUserData) {
					return true;
				}
				this.router.navigate(['/game'], { queryParams: { paymentFalse: true } });
				return false;
			}
		}
		else {
			return true;
		}
	}

	okClick() {
		this.pay = false;
		this.actionStatus.action.next(this.notifyMode());
	}

	next() {
		this.completion = null;
		this.router.navigate(['/game/continents']);
	}

	closeAssignmentPopup(e) {
		e.preventDefault();
		e.stopPropagation();
		this.showAssignmentPopup = false;
	}

	showAssignmentPopupClick() {
		this.showAssignmentPopup = true;
	}

	showAssignmentWarningPopupClick() {
		this.showAssignmentWarning = true;

		this.showAssignmentPopupClick();
	}

	closeAssignmentCongrat() {
		this.showAssignmentDone = false;
		window.location.reload();
	}

	refreshPage() {
		this.router.navigate(['/login']);
		setTimeout(() => {
			window.location.reload();
		}, 50);
	}

	goToGame(item) {
		var route = item.assignment.lesson;
		this.showAssignmentPopup = false;
		if (item.gameType === 'quiz') {
			this.router.navigate(['/game/quiz-activity/' + item.gameId]);
		} else {
			this.router.navigate(['/game/activity-list/' + route]);
		}

		this.showAssignmentPopup = false;
	}

	notifyMode() {
		let result = 1;
		let payedCount = 0;

		if (this.childs && this.childs.length > 0) {
			this.childs.forEach(child => {
				if (child.paymentStatus) {
					payedCount++;
				}
			});

			if (payedCount == 0 && !this.parentData.isUsedTrial && this.childs.length == 1) {
				result = 1;
			} else if (payedCount == 0 && this.parentData.isUsedTrial && this.childs.length == 1) {
				result = 2;
			} else if (payedCount == 0 && this.childs.length > 1) {
				result = 2;
			} else {
				result = 3;
			}

			if ((this.userInfo.activities["1-1-2"] && this.userInfo.activities["1-1-2"]["2"])
				|| (this.userInfo.activities["1-1-2"] && this.userInfo.activities["1-1-2"]["4"])
				|| (this.userInfo.activities["1-1-3"] && this.userInfo.activities["1-1-3"]["2"])
				|| (this.userInfo.activities["1-1-3"] && this.userInfo.activities["1-1-3"]["5"])
				|| (this.userInfo.activities["1-1-4"] && this.userInfo.activities["1-1-4"]["2"])
				|| (this.userInfo.activities["1-1-4"] && this.userInfo.activities["1-1-4"]["7"])
			) result = 2;
		}
		return result;
	}

	firstChild() {
		let result = false;
		let payedCount = 0;

		if (this.parentData.isUsedTrial) return false;

		if (this.childs && this.childs.length > 1) {
			this.childs.forEach(child => {
				if (child.paymentStatus) {
					payedCount++;
				}
			});
			if (payedCount == 0) {
				result = true;
			}
		}
		else if (this.childs && this.childs.length == 1) {
			result = true;
		}
		return result;
	}
}

import { Component, OnInit, OnDestroy, AfterViewInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';

declare function reciteTheVerse(data: any);
declare function destroyreadingReciteTheVerse();

@Component({
    selector: 'app-recite-verse',
    templateUrl: './recite.verse.html'
})
export class ReciteVerseComponent implements OnInit, OnDestroy {
    protected subscription: Subscription;

    id: string;

    data: any;

    activity: any;
    activities: Observable<any>;

    userInfo: any;

    loading: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        public storage: AngularFireStorage,
        private frame: FrameShowService,
        public activityService: ActivityService,
        private pm: PointsMechanismService) {
        window['angularComponentRef'] = {
            component: this,
            zone: this.ngZone,
            gameFinish: (value) => this.gameFinish(value),
            backToMenu: () => this.backToMenu(),
            nextActivity: () => this.nextActivity(),
            getTotalScore: () => this.getTotalScore(),
            loadingFinished: () => this.loadingFinished()
        };
    }

    ngOnInit() {
        this.frame.useV3();
        this.frame.callAfterDone(() => {
            this.subscription = this.activatedRoute.params.subscribe((data) => {
                this.id = data['id'];

                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });

                this.pm.start(this.id, data['activityId']);

                this.activityService.getActivityData({ zoneId: this.id, activityId: data.activityId }).subscribe(aData => {
                    setTimeout(() => {
                        if (aData.activityData.data.defaultFontFamily) {
                            document.getElementById("game-container").setAttribute('style', 'font-family: ' + aData.activityData.data.defaultFontFamily + ' !important');
                        }

                        reciteTheVerse(aData.activityData);
                    }, 1000);
                    this.data = aData.activityData;
                });
            });
        });
    }

    gameFinish(value: any) {
        this.pm.collectPoint(value.starScore, this.data.progressType);
    }

    backToMenu() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo.totalScore;
    }

    loadingFinished() {
        this.loading = false;
    }

    ngOnDestroy(): void {
        destroyreadingReciteTheVerse();
        window['angularComponentRef'] = null;
        if(this.subscription) this.subscription.unsubscribe();
    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  sideNav = {
    opened: true
  };
  name = environment.name;

  constructor() { }
}

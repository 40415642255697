import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth/auth.service';
import { PAGE } from '../config';
import { StorageService } from '../service/storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLogged) {
            let user = this.storageService.get('userData');

            if (user && user.role) {
                return true;
            }
        }

        this.router.navigate([PAGE.login], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SuperAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isSuperLogged) {
            let user = this.storageService.getSuper('userData');

            if (user && user.role) {
                return true;
            }
        }

        this.router.navigate([PAGE.login], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormControl } from '@angular/forms';
import { ErrorMatcher } from 'src/app/shared/error.matcher';
import { AuthService } from 'src/app/shared/auth.service';
import { LoginActionStatusService } from 'src/app/shared/actionStatus.service';
import { ActivityService } from '../../shared/activity.service';

@Component({
    selector: 'app-inner-log-in',
    templateUrl: './inner-log-in.component.html',
    styleUrls: ['./inner-log-in.component.scss']
})
export class InnerLogInComponent implements OnInit, OnDestroy {
    show: boolean;

    userInfo: any;
    loading: boolean = false;
    incorrect: boolean = false;

    notifyMode = 1;

    passwordFormControl = new FormControl('', [
        Validators.required
    ]);

    matcher = new ErrorMatcher();

    isManualCreated = false;

    constructor(
        private router: Router,
        public storage: AngularFireStorage,
        private auth: AuthService,
        private actionStatus: LoginActionStatusService,
        private activityService: ActivityService,
    ) {
    }

    ngOnInit() {

        this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => { console.log('error', error); });

        this.auth.onPaymentUpdate.subscribe(paymentData => {
            if (paymentData != null && paymentData) this.isManualCreated = !paymentData.fromStripe;
        }, error => { console.log('error', error); });
        this.passwordFormControl.setValue('');

        this.actionStatus.action.subscribe(value => {
            this.notifyMode = value;
            this.show = true;
        });
    }

    loadChildData(userData) {
        this.passwordFormControl.setValue('');
        this.userInfo = userData;
    }

    ngOnDestroy(): void {
    }

    previousState() {
        this.router.navigate(['/game']);
    }

    async loginViaGoogle() {
        this.loading = true;
        this.incorrect = false;
        var googleRes = await this.auth.googleSignin();

        if (googleRes.user) {
            let userStr = JSON.stringify(googleRes.user);
            let userObj = JSON.parse(userStr);
            localStorage.setItem('currentUser', userStr);

            var parentData = await this.activityService.getSelfData({ token: userObj.stsTokenManager.accessToken }).toPromise();

            if (!parentData.result) {
                var parts = googleRes.user.displayName.split(" ");
                var affiliateId = await this.generateAffiliateId({ token: userObj.stsTokenManager.accessToken });

                let value = {
                    firstName: parts[0],
                    lastName: googleRes.user.displayName.replace(parts[0], ""),
                    email: googleRes.user.email,
                    country: "Undefined",
                    childs: [],
                    regDate: new Date().getTime(),
                    affiliateInfo: { affiliateId: affiliateId ? affiliateId : 0, clicks: 0, registrations: 0, freeMonthsEarned: 0 }
                }

                await this.activityService.createSelfData({ token: userObj.stsTokenManager.accessToken, parent: value }).toPromise();

                await this.sleep(3000);
            }

            var adminData = await this.activityService.getSelfData({ collectionID: "adminUserData", token: userObj.stsTokenManager.accessToken }).toPromise();

            if (adminData.result) {
                adminData = adminData.result;
                adminData.id = googleRes.user.uid;

                if (adminData['role'] == "super_administrator") {
                    localStorage.setItem('superAdmin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('superAdmin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('superAdmin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));

                    await this.activityService.setSuperAdminAT({ token: userObj.stsTokenManager.accessToken }).toPromise();

                    this.router.navigate(['/admin/super/dashboard']);
                } else {
                    localStorage.setItem('quranera_admin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('quranera_admin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('quranera_admin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));
                    this.router.navigate(['/admin']);
                }
            } else {
                this.loading = false;
                this.router.navigate(['/cabinet']);
            }
        } else {
            localStorage.removeItem('currentUser');
            this.loading = false;
        }
    }

    async loginViaApple() {
        this.loading = true;
        this.incorrect = false;
        var googleRes = await this.auth.appleSignin();

        if (googleRes.user) {
            let userStr = JSON.stringify(googleRes.user);
            let userObj = JSON.parse(userStr);
            localStorage.setItem('currentUser', userStr);

            var parentData = await this.activityService.getSelfData({ token: userObj.stsTokenManager.accessToken }).toPromise();

            if (!parentData.result) {
                var parts = googleRes.user.displayName.split(" ");
                var affiliateId = await this.generateAffiliateId({ token: userObj.stsTokenManager.accessToken });

                let value = {
                    firstName: parts[0],
                    lastName: googleRes.user.displayName.replace(parts[0], ""),
                    email: googleRes.user.email,
                    country: "Undefined",
                    provider: "Apple.com",
                    childs: [],
                    regDate: new Date().getTime(),
                    affiliateInfo: { affiliateId: affiliateId ? affiliateId : 0, clicks: 0, registrations: 0, freeMonthsEarned: 0 }
                }

                await this.activityService.createSelfData({ token: userObj.stsTokenManager.accessToken, parent: value });
                await this.sleep(3000);
            } else

                var adminData = await this.activityService.getSelfData({ collectionID: "adminUserData", token: userObj.stsTokenManager.accessToken }).toPromise();

            if (adminData.result) {
                adminData = adminData.result;
                adminData.id = googleRes.user.uid;

                if (adminData['role'] == "super_administrator") {
                    localStorage.setItem('superAdmin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('superAdmin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('superAdmin_userData', JSON.stringify({ ...adminData, uid: adminData.id }));

                    await this.activityService.setSuperAdminAT({ token: userObj.stsTokenManager.accessToken }).toPromise();

                    this.router.navigate(['/admin/super/dashboard']);
                } else {
                    localStorage.setItem('quranera_admin_accessToken', userObj.stsTokenManager.accessToken);
                    localStorage.setItem('quranera_admin_expirationTime', userObj.stsTokenManager.expirationTime);
                    localStorage.setItem('quranera_admin_userData', JSON.stringify({
                        ...adminData,
                        uid: adminData.id
                    }));
                    this.router.navigate(['/admin']);
                }
            } else {
                this.loading = false;
                this.router.navigate(['/cabinet']);
            }
        } else {
            localStorage.removeItem('currentUser');
            this.loading = false;
        }
    }

    async generateAffiliateId(data): Promise<any> {
        var result = await this.activityService.generateAID(data).toPromise();
        return result.ID;
    }

    appendScriptToBody(email) {
        if (email) {
            const s = document.createElement("script");
            s.setAttribute('id', 'profitwell-js');
            s.setAttribute('data-pw-auth', 'ddef438d0364d959a508bc1cc2c4d5c1');
            s.setAttribute('type', 'text/javascript');
            s.innerHTML = "(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');profitwell('start', { 'user_email': '" + email + "' });";
            document.body.appendChild(s);
        }
    }

    login() {
        this.loading = true;
        this.incorrect = false;

        var parentEmail = localStorage.getItem("parentEmail");

        this.auth.login(parentEmail, this.passwordFormControl.value).then(async result => {
            this.loading = false;
            localStorage.setItem('currentUser', JSON.stringify(result.user));

            if (!this.isManualCreated) {
                var notifyMode = this.notifyMode;

                await this.auth.updateParentNotifyfData({ notifyMode }).toPromise();
                this.router.navigate(['/admin/cabinet']);
            } else {
                this.router.navigate(['/admin/cabinet']);
            }
        }).catch(error => {
            this.incorrect = true;
            this.loading = false;
        });
    }

    close(event) {
        if (event.path[0].className.includes('alert-pop-up')) {
            this.show = false;
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
